// Map.js
import React, { useImperativeHandle, forwardRef, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import tt from '@tomtom-international/web-sdk-maps';
import { getIconForSpot } from './getIconForSpot';
import locationIcon from './assets/location.svg';
import searchLocationIcon from './assets/search-location.svg';
import { containerStyleMap, gtaBounds } from './constants';
import setupTileCache from './setupTileCache';
import { asyncDebounce } from './mapUtils/debouncedFunctions';
import { generateClusters, renderClusters } from './mapUtils/clusterUtils';
import { createMarker, removeMarkers } from './mapUtils/markerUtils';
import { loadMapResources } from './utils/loadMapResources';
import { useSearchParams } from 'react-router-dom';

const MapComponent = forwardRef(({ userLocation, parkingSpots, setVisibleSpots, searchCoords, selectedTypes, locationActive, isLoading }, ref) => {
  const [map, setMap] = useState(null);
  const markersRef = useRef([]);
  const userLocationMarkerRef = useRef(null);
  const searchLocationMarkerRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isMapScriptLoaded, setIsMapScriptLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const lastSearchCoordsRef = useRef(null);

  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  const flyToOptions = useMemo(() => ({
    duration: prefersReducedMotion ? 0 : 1000,
    essential: true,
    easing: prefersReducedMotion ? undefined : (t) => t * (2 - t),
    animate: true
  }), [prefersReducedMotion]);

  // Track whether we're using URL parameters
  const hasUrlParams = useMemo(() => {
    return Boolean(searchParams.get('lat') && searchParams.get('lng'));
  }, [searchParams]);

  const initialLocationRef = useRef(() => {
    const urlLng = searchParams.get('lng');
    const urlLat = searchParams.get('lat');
    const urlZoom = searchParams.get('z');

    if (urlLat && urlLng) {
      return {
        longitude: parseFloat(urlLng),
        latitude: parseFloat(urlLat),
        zoom: urlZoom ? parseFloat(urlZoom) : 14
      };
    }

    return {
      longitude: -79.3832,
      latitude: 43.6532,
      zoom: 14
    };
  }).current();

  const updateUrlFromMap = useCallback((newCenter, newZoom) => {
    const params = new URLSearchParams(searchParams);
    params.set('lat', newCenter.lat.toFixed(6));
    params.set('lng', newCenter.lng.toFixed(6));
    params.set('z', newZoom.toFixed(2));
    setSearchParams(params, { replace: true });
  }, [searchParams, setSearchParams]);

  // Load map resources first
  useEffect(() => {
    loadMapResources()
      .then(() => setIsMapScriptLoaded(true))
      .catch(console.error);
  }, []);

  // Initialize map only after resources are loaded
  useEffect(() => {
    if (!isMapScriptLoaded) return;
    
    const initializeMap = async () => {
      if (map) return;
      
      const newMap = tt.map({
        key: 'UxHKXSr7GAr9zmkJSmnqbscIN6JMhlqy',
        container: 'map',
        style: 'https://api.tomtom.com/style/1/style/22.2.1-*?map=basic_main&poi=poi_main',
        center: [initialLocationRef.longitude, initialLocationRef.latitude],
        zoom: initialLocationRef.zoom,
        maxBounds: [[gtaBounds.west, gtaBounds.south], [gtaBounds.east, gtaBounds.north]],
        minZoom: 10,
        maxZoom: 16,
        vectorTileSourceId: 'vectorTiles',
        animationOptions: {
          animate: false
        }
      });

      newMap.once('load', () => {
        setIsMapLoaded(true);
        newMap.animationOptions = {
          animate: true,
          duration: 1250,
          easing: 'easeInOut'
        };
      });

      setupTileCache(newMap);
      setMap(newMap);
    };

    initializeMap();
  }, [isMapScriptLoaded, map, initialLocationRef.latitude, initialLocationRef.longitude, initialLocationRef.zoom]);

  // Add moveend event listener to update URL
  useEffect(() => {
    if (map) {
      const handleMoveEnd = () => {
        const center = map.getCenter();
        const zoom = map.getZoom();
        updateUrlFromMap({ lat: center.lat, lng: center.lng }, zoom);
      };

      map.on('moveend', handleMoveEnd);
      return () => {
        map.off('moveend', handleMoveEnd);
      };
    }
  }, [map, updateUrlFromMap]);

  // Handle user location marker
  useEffect(() => {
    if (!map || !isMapLoaded) return;

    // Only handle user location marker
    if (userLocation && locationActive) {
      const marker = createMarker(
        map,
        [userLocation.longitude, userLocation.latitude],
        locationIcon,
        [28, 28]
      );

      if (userLocationMarkerRef.current) {
        userLocationMarkerRef.current.remove();
      }
      userLocationMarkerRef.current = marker;

      // Only center on user location if there are no URL parameters
      if (!hasUrlParams) {
        map.flyTo({
          center: [userLocation.longitude, userLocation.latitude],
          zoom: 14,
          ...flyToOptions
        });
      }
    } else if (!locationActive && userLocationMarkerRef.current) {
      userLocationMarkerRef.current.remove();
      userLocationMarkerRef.current = null;
    }
  }, [map, isMapLoaded, userLocation, locationActive, flyToOptions, hasUrlParams]);

  // Update clusters based on current zoom level
  const updateClusters = useCallback(() => {
    if (!map || isLoading) return;

    removeMarkers(markersRef.current);
    const clusters = generateClusters(map, parkingSpots, selectedTypes, 50, getIconForSpot);
    renderClusters(map, clusters, markersRef);
  }, [map, parkingSpots, selectedTypes, isLoading]);

  // Update visible spots based on current map bounds
  const updateVisibleSpots = useCallback(async () => {
    if (map && parkingSpots.length > 0) {
      const bounds = map.getBounds();
      const visible = parkingSpots.filter(spot =>
        bounds.contains([spot.lng, spot.lat]) &&
        (selectedTypes.has(spot.type) || (spot.evCharger && spot.evCharger !== "Not Available" && selectedTypes.has("evCharger")))
      );
      setVisibleSpots(visible);
    }
  }, [map, parkingSpots, selectedTypes, setVisibleSpots]);

  // Debounce functions for performance
  const debouncedUpdateClusters = useMemo(() => asyncDebounce(updateClusters, 400), [updateClusters]);
  const debouncedUpdateVisibleSpots = useMemo(() => asyncDebounce(updateVisibleSpots, 400), [updateVisibleSpots]);

  // Set up event listeners
  useEffect(() => {
    if (map) {
      debouncedUpdateClusters();
      debouncedUpdateVisibleSpots();

      map.on('zoomend', debouncedUpdateClusters);
      map.on('moveend', debouncedUpdateVisibleSpots);

      return () => {
        map.off('zoomend', debouncedUpdateClusters);
        map.off('moveend', debouncedUpdateVisibleSpots);
        debouncedUpdateClusters.cancel();
        debouncedUpdateVisibleSpots.cancel();
      };
    }
  }, [map, debouncedUpdateClusters, debouncedUpdateVisibleSpots]);

  // Center map on user location when it changes
  useEffect(() => {
    if (map && isMapLoaded && userLocation && !hasUrlParams) {
      map.flyTo({
        center: [userLocation.longitude, userLocation.latitude],
        zoom: 14,
        ...flyToOptions
      });
    }
  }, [map, isMapLoaded, userLocation, flyToOptions, hasUrlParams]);

  // Center map on search coordinates when they change
  useEffect(() => {
    if (!map || !searchCoords) {
      // Remove search marker when search is cleared
      if (searchLocationMarkerRef.current) {
        searchLocationMarkerRef.current.remove();
        searchLocationMarkerRef.current = null;
      }
      lastSearchCoordsRef.current = null;
      return;
    }

    const newCenter = [parseFloat(searchCoords.lon), parseFloat(searchCoords.lat)];
    
    // Only fly to location if these are new search coordinates
    if (!lastSearchCoordsRef.current || 
        lastSearchCoordsRef.current.lon !== searchCoords.lon || 
        lastSearchCoordsRef.current.lat !== searchCoords.lat) {
      
      map.flyTo({
        center: newCenter,
        zoom: 15,
        ...flyToOptions
      });
      
      // Update URL when searching
      updateUrlFromMap({ lat: parseFloat(searchCoords.lat), lng: parseFloat(searchCoords.lon) }, 15);
      
      // Store the current search coordinates
      lastSearchCoordsRef.current = searchCoords;
    }

    // Update or create search marker
    if (searchLocationMarkerRef.current) {
      searchLocationMarkerRef.current.setLngLat(newCenter);
    } else {
      searchLocationMarkerRef.current = createMarker(map, newCenter, searchLocationIcon, [40, 40]);
    }
  }, [map, searchCoords, flyToOptions, updateUrlFromMap]);

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    handleMarkerClick: (lat, lng) => {
      if (map) {
        map.flyTo({
          center: [lng, lat],
          zoom: 16,
          ...flyToOptions
        });
      }
    },
    centerOnUserLocation: (location) => {
      if (map && location) {
        map.flyTo({
          center: [location.longitude, location.latitude],
          zoom: 14,
          ...flyToOptions
        });
        updateUrlFromMap(
          { lat: location.latitude, lng: location.longitude },
          14
        );
      }
    }
  }));

  // Handle markers cleanup separately
  useEffect(() => {
    const markers = markersRef.current;
    return () => {
      if (markers) {
        markers.forEach(marker => marker.remove());
      }
    };
  }, []);

  return <div id="map" style={containerStyleMap}></div>;
});

export default React.memo(MapComponent);
